import * as i0 from '@angular/core';
import { APP_INITIALIZER, Injector, Component, inject, ChangeDetectorRef, Injectable, EventEmitter, ViewContainerRef, ViewChild, Output, ApplicationRef, EnvironmentInjector, createComponent, Inject } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import * as i1 from '@angular/common';
import { CommonModule, DOCUMENT } from '@angular/common';
import { style, trigger, state, transition, animate, keyframes, group, query, animateChild } from '@angular/animations';
import { BehaviorSubject, Subject, filter, map, take } from 'rxjs';
const _c0 = ["dialogContent"];
function DialogComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@overlayAnimation", ctx_r0.overlayAnimationState);
  }
}
function DialogComponent_ng_template_4_Template(rf, ctx) {}
function setupServiceLocator(injector) {
  ServiceLocator.injector = injector;
}
class ServiceLocator {}
function generateRandomString() {
  return Math.random().toString(36).substring(2, 12);
}
function provideNgVibeDialog() {
  return [provideAnimations(), {
    provide: APP_INITIALIZER,
    useFactory: injector => () => setupServiceLocator(injector),
    deps: [Injector],
    multi: true
  }];
}
class DefaultLoaderComponent {
  static {
    this.ɵfac = function DefaultLoaderComponent_Factory(t) {
      return new (t || DefaultLoaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DefaultLoaderComponent,
      selectors: [["ng-vibe-dialog-default-loader"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 0,
      consts: [[1, "box-position"], [1, "loader-center"], [1, "loader"]],
      template: function DefaultLoaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵelement(2, "span", 2);
          i0.ɵɵelementEnd()();
        }
      },
      styles: [".box-position[_ngcontent-%COMP%]{height:auto;left:50%;margin:0 auto;position:absolute;text-align:center;top:50%;transform:translate(-50%,-40%)}.box-position[_ngcontent-%COMP%]   .loader-center[_ngcontent-%COMP%]{align-items:center;display:flex;justify-content:center}.box-position[_ngcontent-%COMP%]   .loader-center[_ngcontent-%COMP%]   .loader[_ngcontent-%COMP%]{width:48px;height:48px;display:inline-block;position:relative}.box-position[_ngcontent-%COMP%]   .loader-center[_ngcontent-%COMP%]   .loader[_ngcontent-%COMP%]:after, .box-position[_ngcontent-%COMP%]   .loader-center[_ngcontent-%COMP%]   .loader[_ngcontent-%COMP%]:before{content:\"\";box-sizing:border-box;width:48px;height:48px;border-radius:50%;background:var(--drawer-default-loader-color);position:absolute;left:0;top:0;animation:_ngcontent-%COMP%_animloader 2s linear infinite;opacity:0}.box-position[_ngcontent-%COMP%]   .loader-center[_ngcontent-%COMP%]   .loader[_ngcontent-%COMP%]:after{animation-delay:1s}@keyframes _ngcontent-%COMP%_animloader{0%{transform:scale(0);opacity:1}to{transform:scale(1);opacity:0}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'ng-vibe-dialog-default-loader',
      standalone: true,
      template: "<div class=\"box-position\">\n  <div class=\"loader-center\">\n    <span class=\"loader\"></span>\n  </div>\n</div>\n",
      styles: [".box-position{height:auto;left:50%;margin:0 auto;position:absolute;text-align:center;top:50%;transform:translate(-50%,-40%)}.box-position .loader-center{align-items:center;display:flex;justify-content:center}.box-position .loader-center .loader{width:48px;height:48px;display:inline-block;position:relative}.box-position .loader-center .loader:after,.box-position .loader-center .loader:before{content:\"\";box-sizing:border-box;width:48px;height:48px;border-radius:50%;background:var(--drawer-default-loader-color);position:absolute;left:0;top:0;animation:animloader 2s linear infinite;opacity:0}.box-position .loader-center .loader:after{animation-delay:1s}@keyframes animloader{0%{transform:scale(0);opacity:1}to{transform:scale(1);opacity:0}}\n"]
    }]
  }], null, null);
})();

// in
const bounceIn = [style({
  transform: 'scale3d(0.7, 0.7, 0.7)',
  offset: 0,
  opacity: 0
}), style({
  transform: 'scale3d(1.3, 1.3, 1.3)',
  offset: 0.3,
  opacity: 0.3
}), style({
  transform: 'scale3d(0.95, 0.95, 0.95)',
  offset: 0.6,
  opacity: 1
}), style({
  transform: 'scale3d(1.03, 1.03, 1.03)',
  opacity: 1,
  offset: 0.8
}), style({
  transform: 'scale3d(0.97, 0.97, 0.97)',
  offset: 0.9
}), style({
  transform: 'scale3d(1, 1, 1)',
  offset: 1,
  opacity: 1
})];
const swing = [style({
  offset: 0,
  opacity: 0
}), style({
  transform: 'rotate3d(0, 0, 1, 10deg)',
  offset: 0.2
}), style({
  transform: 'rotate3d(0, 0, 1, -7deg)',
  offset: 0.3,
  opacity: 1
}), style({
  transform: 'rotate3d(0, 0, 1, 3deg)',
  offset: 0.55
}), style({
  transform: 'rotate3d(0, 0, 1, -3deg)',
  offset: 0.8
}), style({
  transform: 'none',
  offset: 1
})];
const zoomIn = [style({
  transform: 'scale3d(0.3, 0.3, 0.3)',
  offset: 0
}), style({
  offset: 0.1,
  opacity: 1
}), style({
  transform: 'scale3d(1, 1, 1)',
  offset: 1
})];
const zoomInRotate = [style({
  transform: 'scale(0.1) rotate(30deg)',
  offset: 0,
  opacity: 0
}), style({
  transform: 'rotate(-10deg)',
  offset: 0.5,
  opacity: 1
}), style({
  transform: 'rotate(3deg)',
  offset: 0.7
}), style({
  transform: 'scale(1)',
  offset: 1
})];
const elastic = [style({
  transform: 'scale3d(1, 1, 1)',
  offset: 0,
  opacity: 0
}), style({
  transform: 'scale3d(1.25, 0.75, 1)',
  offset: 0.3
}), style({
  transform: 'scale3d(0.75, 1.25, 1)',
  offset: 0.4,
  opacity: 1
}), style({
  transform: 'scale3d(1.15, 0.85, 1)',
  offset: 0.5
}), style({
  transform: 'scale3d(0.95, 1.05, 1)',
  offset: 0.6
}), style({
  transform: 'scale3d(1.05, 0.95, 1)',
  offset: 0.7
}), style({
  transform: 'scale3d(1, 1, 1)',
  offset: 1
})];
const jello = [style({
  offset: 0,
  opacity: 0
}), style({
  transform: 'skewX(-12.5deg) skewY(-12.5deg)',
  offset: 0.111
}), style({
  transform: 'skewX(6.25deg) skewY(6.25deg)',
  offset: 0.222
}), style({
  transform: 'skewX(-3.125deg) skewY(-3.125deg)',
  offset: 0.333,
  opacity: 1
}), style({
  transform: 'skewX(1.5625deg) skewY(1.5625deg)',
  offset: 0.444
}), style({
  transform: 'skewX(-0.78125deg) skewY(-0.78125deg)',
  offset: 0.555
}), style({
  transform: 'skewX(0.390625deg) skewY(0.390625deg)',
  offset: 0.666
}), style({
  transform: 'skewX(0.390625deg) skewY(0.390625deg)',
  offset: 0.777
}), style({
  transform: 'skewX(-0.1953125deg) skewY(-0.1953125deg)',
  offset: 0.888
}), style({
  transform: 'none',
  offset: 1
})];
const fadeIn = [style({
  offset: 0,
  opacity: 0
}), style({
  offset: 1,
  opacity: 1
})];
const slideInUp = [style({
  offset: 0,
  opacity: 0,
  transform: 'translate3d(0, 100%, 0)'
}), style({
  offset: 1,
  opacity: 1,
  transform: 'translate3d(0, 0, 0)'
})];
const slideInDown = [style({
  offset: 0,
  opacity: 0,
  transform: 'translate3d(0, -100%, 0)'
}), style({
  offset: 1,
  opacity: 1,
  transform: 'translate3d(0, 0, 0)'
})];
const slideInLeft = [style({
  offset: 0,
  opacity: 0,
  transform: 'translate3d(-100%, 0, 0)'
}), style({
  offset: 1,
  opacity: 1,
  transform: 'translate3d(0, 0, 0)'
})];
const slideInRight = [style({
  offset: 0,
  opacity: 0,
  transform: 'translate3d(100%, 0, 0)'
}), style({
  offset: 1,
  opacity: 1,
  transform: 'translate3d(0, 0, 0)'
})];
// out
const fadeOut = [style({
  offset: 0,
  opacity: 1
}), style({
  offset: 1,
  opacity: 0
})];
const zoomOutWind = [style({
  transform: 'scale3d(.475, .475, .475) translate3d(-42px, 0, 0)',
  offset: 0.4
}), style({
  transform: 'scale(.1) translate3d(400px, 0, 0)',
  'transform-origin': 'top center',
  offset: 1,
  opacity: 0
})];
const bounceOut = [style({
  transform: 'scale3d(1.3, 1.3, 1.3)',
  offset: 0.3
}), style({
  transform: 'scale3d(0.9, 0.9, 0.9)',
  offset: 0.5
}), style({
  transform: 'scale3d(0.3, 0.3, 0.3)',
  opacity: 0,
  offset: 1
})];
const flipOutY = [style({
  transform: 'perspective(400px)',
  offset: 0
}), style({
  transform: 'perspective(400px) rotate3d(0, 1, 0, -15deg)',
  opacity: 1,
  offset: 0.33
}), style({
  transform: 'perspective(400px) rotate3d(0, 1, 0, 90deg)',
  opacity: 0,
  offset: 0.9
})];
const zoomOut = [style({
  opacity: 1,
  offset: 0
}), style({
  offset: 0.5,
  transform: 'scale3d(0.3, 0.3, 0.3)',
  opacity: 0
}), style({
  offset: 1,
  opacity: 0
})];
const zoomOutRotate = [style({
  opacity: 1,
  offset: 0
}), style({
  offset: 0.9,
  transform: 'rotate(200deg) scale(0.1)',
  opacity: 0
})];
const slideOutUp = [style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0
}), style({
  transform: 'translate3d(0, -100%, 0)',
  opacity: 0,
  offset: 1
})];
const slideOutDown = [style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0
}), style({
  transform: 'translate3d(0, 100%, 0)',
  opacity: 0,
  offset: 1
})];
const slideOutLeft = [style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0
}), style({
  transform: 'translate3d(-100%, 0, 0)',
  opacity: 0,
  offset: 1
})];
const slideOutRight = [style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0
}), style({
  transform: 'translate3d(100%, 0, 0)',
  opacity: 0,
  offset: 1
})];
// motion
const wobble = [style({
  transform: 'translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)',
  offset: 0.15
}), style({
  transform: 'translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)',
  offset: 0.3
}), style({
  transform: 'translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)',
  offset: 0.45
}), style({
  transform: 'translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)',
  offset: 0.6
}), style({
  transform: 'translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)',
  offset: 0.75
}), style({
  transform: 'none',
  offset: 1
})];
const boxAnimations = trigger('boxAnimations', [state('initial', style({
  opacity: 0
})), state('reset', style({
  opacity: 1
})),
// in
state('noneIn', style({
  opacity: 1
})), state('bounceIn', style({
  opacity: 1
})), state('swing', style({
  opacity: 1
})), state('zoomIn', style({
  opacity: 1
})), state('zoomInRotate', style({
  opacity: 1
})), state('elastic', style({
  opacity: 1
})), state('jello', style({
  opacity: 1
})), state('fadeIn', style({
  opacity: 1
})), state('slideInUp', style({
  opacity: 1
})), state('slideInDown', style({
  opacity: 1
})), state('slideInLeft', style({
  opacity: 1
})), state('slideInRight', style({
  opacity: 1
})), transition('* => noneIn', animate('10ms', keyframes(fadeIn))), transition('* => bounceIn', animate('1000ms cubic-bezier(0.215, 0.61, 0.355, 1)', keyframes(bounceIn))), transition('* => swing', animate('800ms', keyframes(swing))), transition('* => zoomIn', animate('400ms ease-in', keyframes(zoomIn))), transition('* => zoomInRotate', animate('800ms ease-in', keyframes(zoomInRotate))), transition('* => elastic', animate('1000ms', keyframes(elastic))), transition('* => jello', animate(1000, keyframes(jello))), transition('* => fadeIn', animate('400ms ease-in', keyframes(fadeIn))), transition('* => slideInUp', animate('400ms ease-in', keyframes(slideInUp))), transition('* => slideInDown', animate('400ms ease-in', keyframes(slideInDown))), transition('* => slideInLeft', animate('400ms ease-in', keyframes(slideInLeft))), transition('* => slideInRight', animate('400ms ease-in', keyframes(slideInRight))), transition('* => reset', style({
  opacity: 1
})),
// out
state('noneOut', style({
  opacity: 0
})), state('fadeOut', style({
  opacity: 0
})), state('zoomOutWind', style({
  opacity: 0
})), state('bounceOut', style({
  opacity: 0
})), state('flipOutY', style({
  opacity: 0
})), state('zoomOut', style({
  opacity: 0
})), state('zoomOutRotate', style({
  opacity: 0
})), state('slideOutUp', style({
  opacity: 0
})), state('slideOutDown', style({
  opacity: 0
})), state('slideOutLeft', style({
  opacity: 0
})), state('slideOutRight', style({
  opacity: 0
})), transition('* => noneOut', animate('100ms ease-out', keyframes(fadeOut))), transition('* => fadeOut', animate('300ms ease-out', keyframes(fadeOut))), transition('* => zoomOutWind', animate('400ms ease-out', keyframes(zoomOutWind))), transition('* => bounceOut', animate('400ms ease-out', keyframes(bounceOut))), transition('* => flipOutY', animate('400ms ease-out', keyframes(flipOutY))), transition('* => zoomOut', animate('400ms ease-out', keyframes(zoomOut))), transition('* => zoomOutRotate', animate('400ms ease-out', keyframes(zoomOutRotate))), transition('* => slideOutUp', animate('300ms ease-out', keyframes(slideOutUp))), transition('* => slideOutDown', animate('300ms ease-out', keyframes(slideOutDown))), transition('* => slideOutLeft', animate('300ms ease-out', keyframes(slideOutLeft))), transition('* => slideOutRight', animate('300ms ease-out', keyframes(slideOutRight))),
// motion
transition('* => wobble', animate(1000, keyframes(wobble)))]);
const overlayAnimation = trigger('overlayAnimation', [state('enter', style({
  opacity: 1
})), state('leave', style({
  opacity: 0
})), transition('void => enter', [style({
  opacity: 0
}), group([animate('300ms ease-in', style({
  opacity: 1
})), query('@boxAnimations', animateChild(), {
  optional: true
})])]), transition('enter => leave', [group([query('@boxAnimations', animateChild(), {
  optional: true
}), animate('600ms ease-out', style({
  opacity: 0
}))])])]);
const fadeinOutAnimation = trigger('fadeinOutAnimation', [state('enter', style({
  opacity: 1
})), state('leave', style({
  opacity: 0
})), transition('* => enter', [style({
  opacity: 0
}), group([animate('100ms ease-in', style({
  opacity: 1
})), query('*', animateChild(), {
  optional: true
})])]), transition('enter => leave', [group([query('*', animateChild(), {
  optional: true
}), animate('100ms ease-out', style({
  opacity: 0
}))])])]);
function provideRemoteControl(remoteControl) {
  return [{
    provide: DialogRemoteControl,
    useValue: remoteControl
  }];
}
class DialogComponentHelperService {
  constructor() {
    this.cd = inject(ChangeDetectorRef);
  }
  loadComponent(content, remoteControl, componentType) {
    if (!content || !componentType) return; // Guard condition
    const injector = Injector.create({
      providers: provideRemoteControl(remoteControl)
    });
    content.clear();
    content.createComponent(componentType, {
      injector
    });
    this.cd.detectChanges();
  }
  static {
    this.ɵfac = function DialogComponentHelperService_Factory(t) {
      return new (t || DialogComponentHelperService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DialogComponentHelperService,
      factory: DialogComponentHelperService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogComponentHelperService, [{
    type: Injectable
  }], null, null);
})();
class DialogComponent {
  constructor() {
    this.animationDone = new EventEmitter();
    this.boxAnimationState = 'initial';
    this.overlayAnimationState = 'enter';
    this.contentAnimationState = 'enter';
    this.overlayVisible = false;
    this.helper = inject(DialogComponentHelperService);
  }
  ngAfterViewInit() {
    this.overlayVisible = this.options.showOverlay;
    this.boxAnimationState = this.options.animationIn;
    this.loadComponent();
  }
  prepareComponentForLoad(component, remoteControl) {
    this.mainComponentType = component;
    this.dialogRemoteControl = remoteControl;
    if (!this.loaderComponentType) {
      // If no loader, load the main component immediately
      this.loadComponent();
    }
  }
  stopLoader() {
    if (this.loaderComponentType) {
      this.contentAnimationState = 'leave';
      // timeout for smooth animation
      setTimeout(() => {
        this.loaderComponentType = undefined; // Clear loader component
        this.loadComponent(); // Load main component
      }, 300);
    }
  }
  setLoaderComponent(component) {
    this.loaderComponentType = component;
  }
  loadComponent() {
    const componentType = this.loaderComponentType || this.mainComponentType;
    this.helper.loadComponent(this.dialogContent, this.dialogRemoteControl, componentType);
    this.contentAnimationState = 'enter';
  }
  onAnimationDone(event) {
    if (event.toState === this.options.animationOut) {
      this.animationDone.emit();
    }
  }
  getDialogStyles() {
    let styles = {
      width: this.options.width,
      height: this.options.height,
      minWidth: this.options.minWidth,
      maxWidth: this.options.maxWidth,
      minHeight: this.options.minHeight,
      maxHeight: this.options.maxHeight
    };
    if (this.options.fullScreen) {
      styles = {
        ...styles,
        width: '100vw',
        height: '100vh',
        minWidth: 'auto',
        maxWidth: 'auto',
        minHeight: 'auto',
        maxHeight: 'auto',
        borderRadius: '0'
      };
    }
    return styles;
  }
  static {
    this.ɵfac = function DialogComponent_Factory(t) {
      return new (t || DialogComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DialogComponent,
      selectors: [["ng-vibe-dialog"]],
      viewQuery: function DialogComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dialogContent = _t.first);
        }
      },
      outputs: {
        animationDone: "animationDone"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([DialogComponentHelperService]), i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 4,
      consts: [["class", "ng-vibe-dialog-overlay"], [1, "ng-vibe-dialog-wrapper"], [1, "ng-vibe-dialog", 3, "ngStyle"], [1, "dialog-content"], ["dialogContent", ""], [1, "ng-vibe-dialog-overlay"]],
      template: function DialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, DialogComponent_Conditional_0_Template, 1, 1, "div", 0);
          i0.ɵɵelementStart(1, "div", 1)(2, "div", 2);
          i0.ɵɵlistener("@boxAnimations.done", function DialogComponent_Template_div_animation_boxAnimations_done_2_listener($event) {
            return ctx.onAnimationDone($event);
          });
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵtemplate(4, DialogComponent_ng_template_4_Template, 0, 0, "ng-template", null, 4, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.overlayVisible ? 0 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("@boxAnimations", ctx.boxAnimationState)("ngStyle", ctx.getDialogStyles());
          i0.ɵɵadvance();
          i0.ɵɵproperty("@fadeinOutAnimation", ctx.contentAnimationState);
        }
      },
      dependencies: [CommonModule, i1.NgStyle],
      encapsulation: 2,
      data: {
        animation: [overlayAnimation, boxAnimations, fadeinOutAnimation]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogComponent, [{
    type: Component,
    args: [{
      selector: 'ng-vibe-dialog',
      standalone: true,
      imports: [CommonModule],
      animations: [overlayAnimation, boxAnimations, fadeinOutAnimation],
      providers: [DialogComponentHelperService],
      template: "@if (overlayVisible) {\n<div\n  class=\"ng-vibe-dialog-overlay\"\n  [@overlayAnimation]=\"overlayAnimationState\"\n></div>\n}\n<div class=\"ng-vibe-dialog-wrapper\">\n  <div\n    [@boxAnimations]=\"boxAnimationState\"\n    (@boxAnimations.done)=\"onAnimationDone($event)\"\n    class=\"ng-vibe-dialog\"\n    [ngStyle]=\"getDialogStyles()\"\n  >\n    <div class=\"dialog-content\" [@fadeinOutAnimation]=\"contentAnimationState\">\n      <ng-template #dialogContent></ng-template>\n    </div>\n  </div>\n</div>\n"
    }]
  }], null, {
    dialogContent: [{
      type: ViewChild,
      args: ['dialogContent', {
        read: ViewContainerRef
      }]
    }],
    animationDone: [{
      type: Output
    }]
  });
})();
var AppearanceAnimation;
(function (AppearanceAnimation) {
  AppearanceAnimation["NONE"] = "noneIn";
  AppearanceAnimation["BOUNCE_IN"] = "bounceIn";
  AppearanceAnimation["SWING"] = "swing";
  AppearanceAnimation["ZOOM_IN"] = "zoomIn";
  AppearanceAnimation["ZOOM_IN_ROTATE"] = "zoomInRotate";
  AppearanceAnimation["ELASTIC"] = "elastic";
  AppearanceAnimation["JELLO"] = "jello";
  AppearanceAnimation["FADE_IN"] = "fadeIn";
  AppearanceAnimation["SLIDE_IN_UP"] = "slideInUp";
  AppearanceAnimation["SLIDE_IN_DOWN"] = "slideInDown";
  AppearanceAnimation["SLIDE_IN_LEFT"] = "slideInLeft";
  AppearanceAnimation["SLIDE_IN_RIGHT"] = "slideInRight";
})(AppearanceAnimation || (AppearanceAnimation = {}));
var DisappearanceAnimation;
(function (DisappearanceAnimation) {
  DisappearanceAnimation["NONE"] = "noneOut";
  DisappearanceAnimation["FADE_OUT"] = "fadeOut";
  DisappearanceAnimation["ZOOM_OUT_WIND"] = "zoomOutWind";
  DisappearanceAnimation["BOUNCE_OUT"] = "bounceOut";
  DisappearanceAnimation["FLIP_OUT"] = "flipOutY";
  DisappearanceAnimation["ZOOM_OUT"] = "zoomOut";
  DisappearanceAnimation["ZOOM_OUT_ROTATE"] = "zoomOutRotate";
  DisappearanceAnimation["SLIDE_OUT_UP"] = "slideOutUp";
  DisappearanceAnimation["SLIDE_OUT_DOWN"] = "slideOutDown";
  DisappearanceAnimation["SLIDE_OUT_LEFT"] = "slideOutLeft";
  DisappearanceAnimation["SLIDE_OUT_RIGHT"] = "slideOutRight";
})(DisappearanceAnimation || (DisappearanceAnimation = {}));
const DEFAULT_DIALOG_OPTIONS = {
  width: 'auto',
  height: 'auto',
  minWidth: 'auto',
  maxWidth: 'none',
  minHeight: 'auto',
  maxHeight: 'none',
  fullScreen: false,
  showOverlay: true,
  animationIn: AppearanceAnimation.ZOOM_IN,
  animationOut: DisappearanceAnimation.ZOOM_OUT
};
class HelperService {
  constructor(document) {
    this.document = document;
    this.appRef = inject(ApplicationRef);
    this.environmentInjector = inject(EnvironmentInjector);
  }
  manageView(action, componentRef) {
    if (action === 'attach') {
      this.appRef.attachView(componentRef.hostView);
      this.document.body.appendChild(componentRef.location.nativeElement);
    } else if (action === 'detach') {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }
  }
  createAndConfigureDynamicComponents(remoteControl, childComponent, parentComponent, loaderComponent) {
    const parentComponentRef = createComponent(parentComponent, {
      environmentInjector: this.environmentInjector
    });
    if (loaderComponent) {
      parentComponentRef.instance.setLoaderComponent(loaderComponent);
    }
    parentComponentRef.instance.prepareComponentForLoad(childComponent, remoteControl);
    parentComponentRef.instance.options = remoteControl.options;
    return parentComponentRef;
  }
  static {
    this.ɵfac = function HelperService_Factory(t) {
      return new (t || HelperService)(i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HelperService,
      factory: HelperService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HelperService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
class StateManagementService {
  constructor() {
    this.instanceStatesSubject = new BehaviorSubject(new Map());
    this.instanceStates$ = this.instanceStatesSubject.asObservable();
    this.instances = new Map();
    this.instanceCloseSubject = new Subject();
  }
  addInstance(id, componentRef, remoteControl) {
    this.instances.set(id, componentRef);
    this.updateState(id, remoteControl);
  }
  instanceExists(id) {
    return this.instances.has(id);
  }
  getInstance(id) {
    return this.instances.get(id);
  }
  deleteInstance(id) {
    return this.instances.delete(id);
  }
  getDialogStates$() {
    return this.instanceStatesSubject.asObservable();
  }
  getDialogCloseSubject() {
    return this.instanceCloseSubject;
  }
  listenForClose(id) {
    return this.instanceCloseSubject.asObservable().pipe(filter(event => event.id === id), map(event => event.data), take(1));
  }
  updateState(id, remoteControl) {
    const currentStates = this.instanceStatesSubject.getValue();
    currentStates.set(id, remoteControl);
    this.instanceStatesSubject.next(currentStates);
  }
  removeDialogState(id) {
    const currentStates = this.instanceStatesSubject.getValue();
    if (currentStates.delete(id)) {
      this.instanceStatesSubject.next(currentStates);
    }
  }
  sendCloseData(id, data) {
    this.instanceCloseSubject.next({
      id,
      data
    });
  }
  closeEmmit(id, data) {
    this.deleteInstance(id);
    this.removeDialogState(id);
    this.sendCloseData(id, data);
  }
  static {
    this.ɵfac = function StateManagementService_Factory(t) {
      return new (t || StateManagementService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StateManagementService,
      factory: StateManagementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateManagementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class DialogService {
  constructor() {
    this.helperService = inject(HelperService);
    this.stateService = inject(StateManagementService);
  }
  open(remoteControl, childComponent, loaderComponent) {
    if (this.stateService.instanceExists(remoteControl.id)) {
      return this.stateService.listenForClose(remoteControl.id);
    }
    const parentComponentRef = this.helperService.createAndConfigureDynamicComponents(remoteControl, childComponent, DialogComponent, loaderComponent);
    this.stateService.addInstance(remoteControl.id, parentComponentRef, remoteControl);
    this.helperService.manageView('attach', parentComponentRef);
    return this.stateService.listenForClose(remoteControl.id);
  }
  removeLoaderComponent(id) {
    const dialogComponentRef = this.stateService.getInstance(id);
    if (dialogComponentRef) {
      dialogComponentRef.instance.stopLoader();
    }
  }
  close(id, data) {
    const parentRef = this.stateService.getInstance(id);
    if (parentRef) {
      parentRef.instance.overlayAnimationState = 'leave';
      parentRef.instance.boxAnimationState = parentRef.instance.options.animationOut;
      parentRef.instance.animationDone.pipe(take(1)).subscribe(() => {
        this.helperService.manageView('detach', parentRef);
        this.stateService.closeEmmit(id, data);
      });
    }
  }
  closeAll() {
    const dialogs = this.stateService.instances;
    dialogs.forEach((drawerComponentRef, id) => {
      this.close(id);
    });
  }
  static {
    this.ɵfac = function DialogService_Factory(t) {
      return new (t || DialogService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DialogService,
      factory: DialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class PublicDialogService {
  constructor() {
    this.service = inject(DialogService);
    this.state = inject(StateManagementService);
    /**
     * An Observable that emits the count of active dialogues by listening to the state changes
     * and calculates the total amount active dialogues.
     */
    this.activeDialogsCount$ = this.state.instanceStates$.pipe(map(states => Array.from(states.values()).length));
  }
  /**
   * Retrieves the DialogRemoteControl object associated with a specific dialogue ID.
   * @param {string} id The unique identifier for the dialogue.
   * @returns {DialogRemoteControl | undefined} The DialogRemoteControl object if found, otherwise undefined.
   */
  getRemoteControl(id) {
    return this.state.instanceStatesSubject.getValue().get(id);
  }
  /**
   * Returns an Observable that emits the DialogRemoteControl object for a given dialogue ID.
   * The Observable filters out any undefined states, ensuring subscribers only receive defined DialogRemoteControl objects.
   * @param {string} id The unique identifier for the dialogue.
   * @returns {Observable<DialogRemoteControl | undefined>} An Observable emitting the DialogRemoteControl object or undefined.
   */
  selectRemoteControl$(id) {
    return this.state.instanceStates$.pipe(map(states => states.get(id)), filter(state => state !== undefined));
  }
  /**
   * Triggers the closing of all dialogs.
   */
  closeAll() {
    this.service.closeAll();
  }
  static {
    this.ɵfac = function PublicDialogService_Factory(t) {
      return new (t || PublicDialogService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PublicDialogService,
      factory: PublicDialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PublicDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Provides control over a specific dialog instance, allowing for opening, closing, and configuring the dialog.
 */
class DialogRemoteControl {
  #options;
  #childComponent;
  #loaderComponent;
  /**
   * Sets dialog options, merging provided options with default dialog options.
   * @param {Partial<IDialogOptions>} options Custom options to configure the dialog.
   */
  set options(options) {
    this.#options = {
      ...this.#options,
      ...this.#preSetup(options)
    };
  }
  /**
   * Returns the current configuration options of the dialog.
   * @returns {IDialogOptions} The current set of dialog options.
   */
  get options() {
    return this.#options;
  }
  constructor(component) {
    /** A unique identifier for the dialog instance. */
    this.id = generateRandomString();
    this.#options = DEFAULT_DIALOG_OPTIONS;
    /** Custom payload data that can be passed to the dialog. */
    this.payload = null;
    this.#childComponent = component;
    this.dialogService = ServiceLocator.injector.get(DialogService);
  }
  /**
   * Configures the dialog to show a loader until explicitly stopped. Optionally, a custom loader component can be provided.
   * @param {Type<any>=} component The component to use as a loader. If not provided, a default loader is used.
   */
  withLoader(component) {
    this.#loaderComponent = component || DefaultLoaderComponent;
  }
  /**
   * Stops the loader, if any, and reveals the dialog's main content.
   */
  stopLoader() {
    this.dialogService.removeLoaderComponent(this.id);
  }
  /**
   * Opens the dialog with the configured settings and component. Optionally, a payload can be passed to the dialog.
   * @param {any=} payload Custom data to be passed to the dialog component.
   * @returns {Observable<any>} An Observable that emits when the dialog is closed, returning any result data.
   */
  openDialog(payload) {
    this.payload = payload || null;
    return this.dialogService.open(this, this.#childComponent, this.#loaderComponent);
  }
  /**
   * Closes the dialog and optionally returns data to the opener.
   * @param {any=} data Optional data to return as the result of the dialog.
   */
  closeDialog(data) {
    this.dialogService.close(this.id, data);
  }
  #preSetup(options) {
    return {
      ...DEFAULT_DIALOG_OPTIONS,
      ...options
    };
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { AppearanceAnimation, DialogRemoteControl, PublicDialogService as DialogService, DisappearanceAnimation, provideNgVibeDialog };
