import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { SpinnerService } from '../../../services/spinner-service/spinner.service';

@Component({
  selector: 'custom-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-spinner.component.html',
  styleUrl: './custom-spinner.component.scss',
})
export class CustomSpinnerComponent implements OnInit {
  public isLoading = false;

  constructor(
    public awsAuthService: AuthService,
    private spinnerService: SpinnerService
  ) {
    this.awsAuthService.isLoading$.subscribe((res) => (this.isLoading = res));
  }

  ngOnInit(): void {
    this.spinnerService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
      if (isLoading) {
        setTimeout(() => {
          this.isLoading = false;
        }, 5000);
      }
    });
  }
}
